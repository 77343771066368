var DMCAAbuse, ReportAdult, ScrapStat;

DMCAAbuse = (function() {
  return {
    symbols: 0,
    max_symbols: 255,
    frame: $('#report_frame'),
    button: $('#report_button'),
    form: $('form.abuse-form'),
    counter: $('.abuse-count'),
    textarea: $('.dmca-comment'),
    init: function() {
      var self;
      self = this;
      this.textarea.unbind('change keyup keydown paste');
      this.textarea.on('change keyup keydown paste', function(e) {
        var diff, text;
        diff = self.max_symbols - self.symbols;
        text = $(this).val();
        self.symbols = text.length;
        if (diff <= 0) {
          diff = 0;
          $(this).val(text.slice(0, self.max_symbols));
        }
        return self.counter.html(diff);
      });
      this.button.unbind('mousedown');
      this.button.on("mousedown", function(e) {
        e.preventDefault();
        if (!self.frame.hasClass("active")) {
          self.frame.addClass("active");
          return self.frame.animate({
            top: '20%'
          });
        } else {
          self.frame.removeClass("active");
          return self.frame.animate({
            top: '96%'
          });
        }
      });
      this.form.on("submit", function(e) {
        e.preventDefault();
        self.frame.removeClass("active");
        self.frame.animate({
          top: '96%'
        });
        return self.sendForm($(this).serializeArray());
      });
      return this;
    },
    sendForm: function(content) {
      var self;
      self = this;
      content.push({
        name: 'appbundle_dmca[fingerprint]',
        value: FINGERPRINT
      });
      return $.ajax({
        url: this.form.attr('action'),
        method: 'post',
        data: content,
        success: function(data) {
          if ((data == null) || (data != null ? data.error : void 0)) {
            self.button.html('Error!');
            console.error({
              status: 500,
              statusText: 'Server error'
            });
            return;
          }
          if ((data != null) && !(data != null ? data.error : void 0)) {
            self.button.addClass("done");
            self.button.html('Reported');
            return self.form.trigger('reset');
          }
        },
        error: function(error) {
          self.button.html('Error!');
          return console.error({
            status: 500,
            statusText: error
          });
        }
      });
    }
  };
})();

ScrapStat = (function() {
  return {
    stat_url: null,
    row: null,
    sendReport: function(magnet, source, callback) {
      if (callback == null) {
        callback = null;
      }
      return $.post(this.stat_url, {
        link: magnet,
        fingerprint: FINGERPRINT,
        source: source
      }, function(data) {
        if (callback != null) {
          return callback(data);
        }
      });
    },
    init: function(row) {
      var magnet, self, source;
      self = this;
      if (row != null) {
        this.row = $(row);
        this.stat_url = this.row.data('stat');
        magnet = $('.magnet-button', this.row).attr('href');
        source = $('.label.source', this.row).data('source');
        $('.stats-action', this.row).unbind("mouseup");
        if ((magnet != null) && magnet.length > 0) {
          return $('.stats-action', this.row).on("mouseup", function(e) {
            return self.sendReport(magnet, source);
          });
        }
      }
    }
  };
})();

ReportAdult = (function() {
  return {
    report_button: null,
    sendReport: function(id, url, callback) {
      if (callback == null) {
        callback = null;
      }
      return $.post(url, {
        id: id,
        fingerprint: FINGERPRINT
      }, function(data) {
        if (callback != null) {
          return callback(data);
        }
      });
    },
    init: function(item) {
      var self;
      self = this;
      if (item != null) {
        this.report_button = $(item);
        this.report_button.unbind('mousedown');
        return this.report_button.on("mousedown", function(e) {
          var _this, id, url;
          _this = $(this);
          e.preventDefault();
          id = $(".id", _this.parent().parent().parent()).html();
          url = _this.data('adult');
          return self.sendReport(id, url, function(data) {
            return _this.addClass('hidden');
          });
        });
      }
    }
  };
})();

$(document).ready(function() {
  DMCAAbuse.init();
  $(".report-adult-button").each(function() {
    return ReportAdult.init(this);
  });
  return $(".bls-row").each(function() {
    return ScrapStat.init(this);
  });
});
